import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import Raven from 'raven-js';

import { getHiddenElements } from 'app/utils/url';
import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import {
    PAYMENT_PARAM_RECURRENT,
    PAYMENT_PARAM_ONLINE,
    PAYMENT_PARAM_CASH,
    BILLING_PAYMENT_METHODS,
    PAYMENT_PARAM_SBP,
} from 'app/const/billing-payment-methods';
import { paymentMethodVar, promocodeVar, subscriptionTypeVar } from 'app/apollo/reaction';

export const getBillingPaymentMethod = ({
    statePaymentMethod,
    subscriptionType,
}) => {
    let urlPaymentParam;

    if (isSubscription(subscriptionType)) {
        urlPaymentParam = PAYMENT_PARAM_RECURRENT;
    } else if (statePaymentMethod === 'online') {
        urlPaymentParam = PAYMENT_PARAM_ONLINE;
    } else if (statePaymentMethod === 'cash') {
        urlPaymentParam = PAYMENT_PARAM_SBP;
        // urlPaymentParam = PAYMENT_PARAM_CASH;
    } else if (statePaymentMethod === 'sbp') {
        urlPaymentParam = PAYMENT_PARAM_SBP;
    }

    return {
        urlPaymentParam,
        billingPaymentMethod: BILLING_PAYMENT_METHODS[urlPaymentParam],
    };
};


const isPeriodEmpty = (period) => !period || period === '';

const isNewOrderLocation = (location) => {
    const { pathname } = location;
    return (
        pathname === '/'
        || pathname.startsWith('/menu')
        || pathname.startsWith('/basket')
        || pathname.startsWith('/checkout')
        || pathname.startsWith('/pay/')
    );
};


class BillingParamsUpdater extends React.Component {
    state = {
        hiddenElements: {},
    };

    isDefaultValuesSaved = false;

    isBillingSaved = false;

    componentDidMount() {
        const hiddenElements = getHiddenElements();
        if (hiddenElements) {
            this.setState({ hiddenElements }, () => {
                this.setDefaultValues();
                this.updateUrlParams();
            });
        } else {
            this.setDefaultValues();
            this.updateUrlParams();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            subscriptionTypeQuery: { subscriptionType },
            promocode: { promocode },
        } = this.props;

        const {
            subscriptionTypeQuery: { subscriptionType: prevSubscriptionType },
            promocode: { promocode: prevPromocode },
        } = prevProps;

        this.setDefaultValues();
        this.updateUrlParams();

        const isSubscriptionTypeChanged = prevSubscriptionType !== subscriptionType;
        const isPromocodeChanged = promocode !== prevPromocode;

        if (isSubscriptionTypeChanged || isPromocodeChanged) {
            this.isBillingSaved = false;
        }
    }

    // NOTE Тут ставятся дефолтные параметры в url и apollo cache
    async setDefaultValues() {
        const {
            history,
            basketQuery: { cart },
        } = this.props;

        if (!isNewOrderLocation(history.location)) return;
        if (this.isDefaultValuesSaved) return;
        if (!cart) return;

        const { location: { search } } = history;
        const parsedQuery = queryString.parse(search);
        const {
            promo_code: promocodeParam,
            payment: paymentParam,
        } = parsedQuery;

        const { discount_conditions: { promocode: promocodeFromCart } } = cart;

        const {
            paymentMethod,
            subscriptionType,
        } = this.resolveNextPaymentMethodAndSubscriptionType(paymentParam);

        try {
            const promocodeValue = promocodeParam || promocodeFromCart;

            if (promocodeValue) {
                promocodeVar(promocodeValue);
            }
            paymentMethodVar(paymentMethod);
            subscriptionTypeVar(subscriptionType);
            this.isDefaultValuesSaved = true;
        } catch (error) {
            Raven.captureMessage('[InitApp] Set default value error', {
                extra: {
                    error,
                },
            });
        }
    }

    trimUrlPaymentParam = (urlPaymentParam) => {
        if (!urlPaymentParam) return null;
        const parsedExcessParams = urlPaymentParam.split('?');
        if (parsedExcessParams.length > 1) {
            return parsedExcessParams[0];
        }
        return urlPaymentParam;
    };


    resolveNextPaymentMethodAndSubscriptionType(urlPaymentParam) {
        const {
            filterQuery: { menuFilter: { periods } },
            selectedFilters: { selectedPeriod },
        } = this.props;

        const {
            hiddenElements: {
                payment_button_recurrent: hideRecurrent,
                payment_button_online: hideOnline,
                payment_button_offline: hideCash,
            },
        } = this.state;

        let urlPayment = this.trimUrlPaymentParam(urlPaymentParam);

        if (Array.isArray(urlPaymentParam)) {
            urlPayment = urlPaymentParam.find((p) => Boolean(p));
        }

        let paymentParam = urlPayment || PAYMENT_PARAM_RECURRENT;


        const period = periods.find((p) => p.start === selectedPeriod);
        if (period && period.defaultPaymentMethod) {
            paymentParam = period.defaultPaymentMethod;
        }

        if (hideRecurrent && paymentParam === PAYMENT_PARAM_RECURRENT) {
            if (hideOnline) {
                paymentParam = PAYMENT_PARAM_CASH;
            } else {
                paymentParam = PAYMENT_PARAM_ONLINE;
            }
        }

        if (hideOnline && paymentParam === PAYMENT_PARAM_ONLINE) {
            if (hideRecurrent) {
                paymentParam = PAYMENT_PARAM_CASH;
            } else {
                paymentParam = PAYMENT_PARAM_RECURRENT;
            }
        }

        if (hideCash && paymentParam === PAYMENT_PARAM_CASH) {
            if (hideRecurrent) {
                paymentParam = PAYMENT_PARAM_ONLINE;
            } else {
                paymentParam = PAYMENT_PARAM_RECURRENT;
            }
        }

        const mapping = {
            [PAYMENT_PARAM_RECURRENT]: {
                paymentMethod: 'online',
                subscriptionType: SUBSCRIPTION_TYPES.subscription,
            },
            [PAYMENT_PARAM_ONLINE]: {
                paymentMethod: 'online',
                subscriptionType: SUBSCRIPTION_TYPES.singlePay,
            },
            [PAYMENT_PARAM_CASH]: {
                // paymentMethod: 'cash',
                paymentMethod: 'sbp',
                subscriptionType: SUBSCRIPTION_TYPES.singlePay,
            },
            [PAYMENT_PARAM_SBP]: {
                paymentMethod: 'sbp',
                subscriptionType: SUBSCRIPTION_TYPES.singlePay,
            },
        };
        return mapping[paymentParam] || mapping[PAYMENT_PARAM_ONLINE];
    }

    async updateUrlParams() {
        const {
            history,
            promocode: { promocode },
            subscriptionTypeQuery: { subscriptionType },
            paymentMethod: { paymentMethod },
            selectedFilters: { selectedPeriod },
            basketQuery: {
                cart,
                refetch: refetchBasket,
            },
            updateBilling,
        } = this.props;

        if (!this.isDefaultValuesSaved) return;
        if (!isNewOrderLocation(history.location)) return;
        if (!cart) return;

        const { location: { pathname, search, hash } } = history;
        const parsedQuery = queryString.parse(search);
        const {
            promo_code: promocodeParam,
            payment: paymentParam,
        } = parsedQuery;

        const nextQuery = { ...parsedQuery };

        const { discount_conditions: { promocode: promocodeFromCart } } = cart;
        const nextPromocode = promocode || promocodeFromCart;

        if (nextPromocode !== promocodeParam) {
            nextQuery.promo_code = nextPromocode;
        }

        const {
            urlPaymentParam,
            billingPaymentMethod,
        } = getBillingPaymentMethod({
            statePaymentMethod: paymentMethod,
            subscriptionType,
        });

        if (urlPaymentParam && urlPaymentParam !== paymentParam) {
            nextQuery.payment = urlPaymentParam;
        }

        if (!isEqual(nextQuery, parsedQuery)) {
            const querystr = queryString.stringify(nextQuery);
            history.replace(`${pathname}?${querystr}${hash}`);
        }

        if (!isPeriodEmpty(selectedPeriod) && !this.isBillingSaved) {
            this.isBillingSaved = true;

            const variables = {
                payment_method: billingPaymentMethod,
                promocode: nextPromocode,
                referrerCode: parsedQuery.eeref,
                phone: sessionStorage.getItem('phone'),
                period: selectedPeriod,
            };
            try {
                await updateBilling({ variables });
                refetchBasket();
            } catch (error) {
                Raven.captureException(error, { extra: { scope: 'billing_mutation_error' } });
            }
        }
    }

    render() {
        const { Child, ...otherProps } = this.props;
        return <Child {...otherProps} />;
    }
}

BillingParamsUpdater.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
        }),
        replace: PropTypes.func,
    }).isRequired,
    promocode: PropTypes.shape({
        promocode: PropTypes.string,
    }).isRequired,
    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
    }).isRequired,
    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string,
    }).isRequired,
    paymentMethod: PropTypes.shape({
        paymentMethod: PropTypes.string,
    }).isRequired,

    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({
                start: PropTypes.string.isRequired,
            })),
        }),
    }).isRequired,

    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            discount_conditions: PropTypes.shape({
                promocode: PropTypes.string,
            }).isRequired,
        }),
        refetch: PropTypes.func.isRequired,
    }).isRequired,

    Child: PropTypes.func.isRequired,
    updateBilling: PropTypes.func.isRequired,
};


const withBillingParams = (Wrapped) => (
    (props) => (
        <BillingParamsUpdater
            {...props}
            Child={Wrapped}
        />
    )
);


export default withBillingParams;
