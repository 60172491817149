/* eslint-disable global-require */

import vegXS from './img-xs/veg3.png';
import vegM from './img-m/veg3.jpg';

import fastXS from './img-xs/fast.png';
import fastM from './img-m/fast.jpg';

import liteXS from './img-xs/lite.png';
import liteM from './img-m/lite.jpg';

import homerestXS from './img-xs/homerest.png';
import homerestM from './img-m/homerest.jpg';

import menuXS from './img-xs/menuXS.jpg';

export const veg = {
    m: vegM,
    xs: vegXS,
};

export const speed = {
    m: fastM,
    xs: fastXS,
};

export const lite = {
    m: liteM,
    xs: liteXS,
};

export const forkids = {
    m: require('./kids/kids-2-m.jpg'),
    xs: require('./kids/kids-2-xs.jpg'),
};

export const homerest = {
    m: homerestM,
    xs: homerestXS,
};

export const fasting = {
    m: require('./fasting/fasting-m.jpg'),
    xs: require('./fasting/fasting-xs.jpg'),
};

export const menu = {
    xs: menuXS,
};

export const ny = {
    m: require('./ny/ny-4-xs.jpg'),
    xs: require('./ny/ny-4-xs.jpg'),
};

export const nySweets = {
    m: require('./ny/ny-sweets-xs.jpg'),
    xs: require('./ny/ny-sweets-xs.jpg'),
};

export const hp = {
    m: require('./hp/hp-m.jpg'),
    xs: require('./hp/hp-xs.jpg'),
};

export const christmas = {
    m: require('./christmas/christmas-m.jpg'),
    xs: require('./christmas/christmas-xs.jpg'),
};

export const march = {
    m: require('./march/march-m.jpg'),
    xs: require('./march/march-xs.jpg'),
};

export const picnic = {
    m: require('./picnic/picnic-m.jpg'),
    xs: require('./picnic/picnic-xs.jpg'),
};

export const hits = {
    m: require('./hits/hits-m.jpg'),
    xs: require('./hits/hits-xs.jpg'),
};

export const creativity = {
    m: require('./creativity/creativity-m.jpg'),
    xs: require('./creativity/creativity-xs.jpg'),
};

export const guests = {
    m: require('./guests/guests-m.jpg'),
    xs: require('./guests/guests-xs.jpg'),
};

export const green = {
    m: require('./green/green-m.jpg'),
    xs: require('./green/green-xs.jpg'),
};

export const halloween = {
    m: require('./halloween/halloween-m.jpg'),
    xs: require('./halloween/halloween-xs.jpg'),
};


export const maslenitsa = {
    m: require('./maslenitsa/maslenitsa-m.jpg'),
    xs: require('./maslenitsa/maslenitsa-xs.jpg'),
};

export const easter = {
    m: require('./easter/easter-m.jpg'),
    xs: require('./easter/easter-xs.jpg'),
};

export const dacha = {
    m: require('./dacha/dacha.jpg'),
    xs: require('./dacha/dacha.jpg'),
};

export const soups = {
    m: require('./soups/soups-xs.jpg'),
    xs: require('./soups/soups-xs.jpg'),
};

export const sweets = {
    m: require('./sweets/sweet-xs.jpg'),
    xs: require('./sweets/sweet-xs.jpg'),
};

export const nz = {
    m: require('./nz/nz-xs.jpg'),
    xs: require('./nz/nz-xs.jpg'),
};

export const breakfast = {
    m: require('./breakfast/breakfast-xs.jpg'),
    xs: require('./breakfast/breakfast-xs.jpg'),
};

export const vivelafrance = {
    m: require('./vivelafrance/vivelafrance-m.jpg'),
    xs: require('./vivelafrance/vivelafrance-xs.jpg'),
};

export const dolcevita = {
    m: require('./dolcevita/dolcevita-m.jpg'),
    xs: require('./dolcevita/dolcevita-xs.jpg'),
};

export const ahmad = {
    m: '',
    xs: require('./ahmad/ahmad-xs.jpg'),
};

export const august = {
    m: require('./august/august-m.jpg'),
    xs: require('./august/august-xs.jpg'),
};

export const school = {
    m: require('./school/school-m.jpg'),
    xs: require('./school/school-xs.jpg'),
};

export const film = {
    m: require('./film/film-m.jpg'),
    xs: require('./film/film-xs.jpg'),
};

export const kozel = {
    xs: require('./kozel/kozel-xs.jpg'),
};

export const maxim = {
    m: require('./maxim/maxim_m.jpg'),
    xs: require('./maxim/maxim_xs.jpg'),
};

export const hochland = {
    xs: require('./hochland/hochland_xs.jpg'),
};

export const romantic = {
    m: require('./romantic/romantic_m.jpg'),
    xs: require('./romantic/romantic_xs.jpg'),
};

