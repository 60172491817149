import React from 'react';

interface IUpsellItem {
    type: 'link' | 'button',
    title: React.ReactElement
    description: React.ReactElement
    href?: string,
    tag?: string,
}

const maslenitsaUpsell: IUpsellItem = {
    type: 'button',
    tag: 'maslenitsa',
    title: (
        <>
            Меню на Масленицу
        </>
    ),
    description: (
        <>
            Сытные или сладкие, на завтрак
            <br />
            или на ужин, с готовыми блинами или с самодельными — мы подготовили все варианты наборов,
            чтобы вы смогли устроить настоящую масленичную неделю за считанные минуты!
        </>
    ),
};

const romanticUpsell: IUpsellItem = {
    type: 'link',
    href: '/romantic/',
    title: (
        <>
            Время для свиданий дома
        </>
    ),
    description: (
        <>
            Всё для романтического вечера: от рецептов и продуктов до салфеток-сердечек для сервировки и тем для душевных разговоров.
        </>
    ),
};

export const upsellHashMap = new Map([
    ['maslenitsa', maslenitsaUpsell],
    ['romantic', romanticUpsell],
]);
