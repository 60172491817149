export const BR1169EventsRecord = [
    /**
     * @event 'Main_Scroll'
     */
    {
        eventName: 'Main_Scroll',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, headerName } = data;
            return {
                ...data,
                event: eventName,
                header_name: headerName,
            };
        },
    },
    /**
     * @event 'Pageview'
     */
    {
        eventName: 'Pageview',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, pagePath, pageUrl } = data;
            return {
                ...data,
                event: eventName,
                page_path: pagePath,
                page_url: pageUrl,
            };
        },
    },
    {
        eventName: 'Main_Category_Click',
        description: '',
        meta: ['click'],
        scope: ['index'],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, bannerName } = data;
            return {
                ...data,
                event: eventName,
                banner_name: bannerName,
            };
        },
    },
    {
        eventName: 'Main_Set_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, bannerName } = data;
            return {
                ...data,
                event: eventName,
                banner_name: bannerName,
            };
        },
    },
    {
        eventName: 'Main_Dish_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, dishId, dishName } = data;
            return {
                ...data,
                event: eventName,
                dish_id: dishId,
                dish_name: dishName,
            };
        },
    },
    {
        eventName: 'Main_Reviews_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
    {
        eventName: 'Main_Video_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName } = data;
            return {
                ...data,
                event: eventName,
            };
        },
    },
];
