import React from 'react';

import { selectedPeriodVar } from 'app/apollo/reaction';
import { TCurrentSetType } from './components/BasketSetsSelection/BasketSetsSelection';

export const useBigSetBannerByPeriod = (): TCurrentSetType => {
    const period = selectedPeriodVar();

    const selectedData = Math.floor(new Date(period).getTime() / 1000);
    const mslStartData = Math.floor(new Date('2025-02-24').getTime() / 1000);

    if (selectedData >= mslStartData) {
        return 'maslenitsa';
    }

    return 'romantic';
};
