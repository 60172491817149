/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import BasketWithCustomization from './BasketWithCustomization/index';
import { BASKET_PAGE_CART_SECTIONS_WITH_DISHES } from './graphql/basketListArea.graphql';

export const makePrimetiveSection = (sections) => {
    if (!sections) {
        return [];
    }

    const flatSection = sections.reduce((acc, section) => [...acc, ...section.items], []);
    // eslint-disable-next-line no-underscore-dangle
    const primetiveSection = flatSection.map((item) => `${item.__typename}:${item.dish_id}`);
    return primetiveSection;
};

const BasketListArea = (props) => {
    const {
        handleAddMoreButtonClick,
        handleChangePortion,
        handleRemoveDish,
        onClickMobilePortions,
        mutateItemInCart,
        isCartUpdating,
    } = props;

    /* DA */
    const { data } = useQuery(BASKET_PAGE_CART_SECTIONS_WITH_DISHES, {
        fetchPolicy: 'cache-first',
    });

    /* SELECTORS */
    const primetiveSection = useMemo(
        () => makePrimetiveSection(data?.cart?.sections || []),
        [data?.cart?.sections],
    );


    /* RENDER */
    return (
        <BasketWithCustomization
            handleAddMoreButtonClick={handleAddMoreButtonClick}
            handleChangePortion={handleChangePortion}
            handleRemoveDish={handleRemoveDish}
            onClickMobilePortions={onClickMobilePortions}
            primetiveSection={primetiveSection}
            mutateItemInCart={mutateItemInCart}
            isCartUpdating={isCartUpdating}
        />
    );
};

export default React.memo(BasketListArea);

/*

DEPRECATED

const BasketDishesList = (props) => {
    const {
        localeContext: { locale },
        // basketQuery: { cart: { sections } },
        selectedFilters: { selectedPeriod },
        menuDatesContext: { state: menuDatesState },
        invisibleCategories,
        invisibleDishes,
        showAddMoreButton,
        isEditEnabled,
        subscriptionType,
        preparedBasketAreas,
        // ACTIONS
        handleChangePortion,
        handleRemoveDish,
        handleOpenMobileDishDetails,
        handleAddMoreButtonClick,
        onClickMobilePortions,
        sections = { sections },
        // openDishDetails,
    } = props;

    const { data } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);

    const isSubscriptionActivated = useMemo(
        () => isSubscription(subscriptionType),
        [subscriptionType],
    );

    if (!data) return null;

    const { deliveryAddress: { address: { country_subdivision: subdivision } } } = data;

    return (
        <BasketCategoriesContainer
            subdivision={subdivision}
            preparedBasketAreas={preparedBasketAreas}
            invisibleCategories={invisibleCategories}
            invisibleDishes={invisibleDishes}
            showAddMoreButton={showAddMoreButton}
            isEditEnabled={isEditEnabled}
            isSubscriptionActivated={isSubscriptionActivated}
            categories={sections}
            locale={locale}
            selectedPeriod={selectedPeriod}
            subscriptionType={subscriptionType}
            menuDatesState={menuDatesState}
            // ACTIONS
            onChangePortion={handleChangePortion}
            onClickRemoveDish={handleRemoveDish}
            onClickMobilePortions={onClickMobilePortions}
            onClickMobileDishDetails={handleOpenMobileDishDetails}
            onClickAddMoreButton={handleAddMoreButtonClick}
        />
    );
};


*/
