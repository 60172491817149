class UniqueIDGenerator {
    constructor() {
        this.lastTimestamp = null;
        this.counter = 0;
        this.lock = false;
    }

    generateId() {
        if (this.lock) {
            return;
        }
        this.lock = true;

        let timestamp = Date.now();

        if (timestamp === this.lastTimestamp) {
            this.counter++;
            if (this.counter > 1000) {
                while (timestamp === this.lastTimestamp) {
                    timestamp = Date.now();
                }
                this.counter = 0;
            }
        } else {
            this.counter = 0;
        }

        this.lastTimestamp = timestamp;

        const randomPart = Math.floor(Math.random() * 10000);

        const uniqueId = `${timestamp}${this.counter.toString().padStart(3, '0')}${randomPart.toString().padStart(4, '0')}`;

        this.lock = false;

        return uniqueId;
    }
}

export const idGenerator = new UniqueIDGenerator();
