import React, {
    useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
// @ts-ignore
import cookies from 'js-cookie';

import { STORE_ENV_DATA } from 'app/graphql/network/envDataMutation';
import {
    abTestDataContext,
} from 'app/containers/contexts/abTest.context';
import { selectedPeriodVar } from 'app/apollo/reaction';

import { analyticService } from 'global/services';
import { STORE_USER_DATA } from 'app/containers/DataSaverUser/storeUserDataMutation';
import { idGenerator } from '../classes/uniqueIdGenerator';

// ХУК ВЫПОЛНЯЕТ ОДИН ИЗ ДВУХ ХЭНДЛЕРОВ В ЗАВИСИМОСТИ ОТ ТОГО, НА КАКУЮ СТРАНИЦУ ЗАШЕЛ ПОЛЬЗОВАТЕЛЬ

const USER_QUERY_FOR_AB_GROUPS = gql`
        query USER_QUERY_FOR_AB_GROUPS {
            user {
                id
                ab_groups
            }
        }
`;

const participantId = idGenerator.generateId();

export const useTestLPDefine = (
    realm: 'target' | 'rest',
    nodeId: string,
    testName: string,
    selfExecutable: boolean = true,
) => {
    const mainBannerRootNode: HTMLElement | null = document.querySelector(`#${nodeId}`);
    const templateABTestValue = mainBannerRootNode?.dataset.ab_test_value;

    const isABTestValueSet = useRef(false);
    const period = selectedPeriodVar();
    const [storeEnvData] = useMutation(STORE_ENV_DATA);
    const [storeUserData] = useMutation(STORE_USER_DATA);
    const { fetchABTestValue, setABTestValue } = useContext<any>(abTestDataContext);

    const { data: { user } } = useQuery(USER_QUERY_FOR_AB_GROUPS, {
        fetchPolicy: 'cache-only',
    });

    const isUserInABTestAlready = useMemo(
        () => {
            const testValueFromCookie = cookies.get(testName?.toUpperCase());
            return user?.ab_groups.includes(testName) || testValueFromCookie !== undefined;
        },
        [user, testName],
    );

    const handleSetAbTestValue = useCallback(
        () => {
            setABTestValue(testName, templateABTestValue);
            cookies.set(testName?.toUpperCase(), templateABTestValue, { expires: 30 });
            analyticService.push({
                eventName: 'Start_test',
                userId: user?.id,
                participantId,
                testName,
            });
        },
        [setABTestValue, templateABTestValue, testName, user?.id],
    );

    const handleCheckAndSetTest = useCallback(
        () => {
            if (!setABTestValue || isABTestValueSet?.current) return;

            const isABTestValueFromTemplateToSet = templateABTestValue !== undefined && !isUserInABTestAlready;
            if (isABTestValueFromTemplateToSet) {
                const variables = {
                    abGroup: `${testName}__${templateABTestValue}`,
                    period,
                };
                storeEnvData({ variables }).then(
                    () => {
                        handleSetAbTestValue();
                    },
                );
                storeUserData({ variables: { participantId } });
            }
        },
        [
            setABTestValue, period, testName,
            isUserInABTestAlready, templateABTestValue,
            handleSetAbTestValue, storeEnvData, storeUserData,
        ],
    );

    const handleCheckAndTestValue = useCallback(
        () => {
            if (!fetchABTestValue) return;

            const ABTestValueFromCookie = cookies.get(testName);
            const isABTestNotFetched = ABTestValueFromCookie === undefined;

            if (isABTestNotFetched) {
                fetchABTestValue(testName).then((testValue: string) => {
                    if (testValue) {
                        cookies.set(testName?.toUpperCase(), testValue);
                    }
                });
            }
        },
        [fetchABTestValue, testName],
    );

    const getActionToExecute = useCallback(
        () => {
            const actionsByRealm = {
                target: handleCheckAndSetTest,
                rest: handleCheckAndTestValue,
            };
            return actionsByRealm[realm];
        },
        [handleCheckAndSetTest, handleCheckAndTestValue, realm],
    );


    useEffect(
        () => {
            if (!selfExecutable) return;
            const abTestHandler = getActionToExecute();
            abTestHandler();
            isABTestValueSet.current = true;
        },
        [getActionToExecute, selfExecutable],
    );

    return [getActionToExecute];
};
