import { gql } from '@apollo/client';

export default gql`
    query DeliveryCart($deliveryId: ID!, $clear: Boolean) {
        cart: deliveryCart(delivery_id: $deliveryId, clear: $clear) {
            __typename
            period
            primetiveSection @client
            typeOfSet
            hasNewItems
            sections {
                __typename
                id
                title
                gift_category
                cartType
                items {
                    dish_id
                    type
                    portions
                    price
                    discount_price
                    dish {
                        id
                        title
                        caption
                        umAlias
                        price
                        nutrition {
                            carbohydrate {
                                title
                                value
                                unit
                            }
                            fat {
                                title
                                value
                                unit
                            }
                            protein {
                                title
                                value
                                unit
                            }
                            calories {
                                title
                                value
                                unit
                            }
                        }
                        weight
                        portions
                        previewImage
                        composition
                        cooking_time
                        cook_until
                        parentId
                        categoryId: category_id
                        tags {
                            id
                            title
                        }
                        recipeUrl
                    }
                }
            }
            totals {
                delivery_price
                static_discount_price
                static_common_price
                total_price
                total_discount_price
                total_common_price
                discount_amount
                applied_discount_amount
                referral_amount
                promocode_amount
            }
            discount_conditions {
                promocode
                promocode_status
                isCertificate
                isPromoCodeActivated
                promoCodeApplyingErrorMessage
                isPromocodeCombineWithDiscount
                differenceBetweenPromocodeAndOrder
                isPromoCodeExists
                isSubscriptionActivated
                deliveriesCount
                discountType
                discountValue
                discountDuration
            }
        }
    }`;
